import * as _vue4 from "vue";
var _vue3 = "default" in _vue4 ? _vue4.default : _vue4;
import * as _types3 from "element-ui/lib/utils/types";
var _types2 = "default" in _types3 ? _types3.default : _types3;
var exports = {};
exports.__esModule = true;
exports.isMac = exports.isEmpty = exports.isEqual = exports.arrayEquals = exports.looseEqual = exports.capitalize = exports.kebabCase = exports.autoprefixer = exports.isFirefox = exports.isEdge = exports.isIE = exports.coerceTruthyValueToArray = exports.arrayFind = exports.arrayFindIndex = exports.escapeRegexpString = exports.valueEquals = exports.generateId = exports.getValueByPath = undefined;
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};
exports.noop = noop;
exports.hasOwn = hasOwn;
exports.toObject = toObject;
exports.getPropByPath = getPropByPath;
exports.rafThrottle = rafThrottle;
exports.objToArray = objToArray;
var _vue = _vue3;
var _vue2 = _interopRequireDefault(_vue);
var _types = _types2;
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var hasOwnProperty = Object.prototype.hasOwnProperty;
function noop() {}
;
function hasOwn(obj, key) {
  return hasOwnProperty.call(obj, key);
}
;
function extend(to, _from) {
  for (var key in _from) {
    to[key] = _from[key];
  }
  return to;
}
;
function toObject(arr) {
  var res = {};
  for (var i = 0; i < arr.length; i++) {
    if (arr[i]) {
      extend(res, arr[i]);
    }
  }
  return res;
}
;
var getValueByPath = exports.getValueByPath = function getValueByPath(object, prop) {
  prop = prop || "";
  var paths = prop.split(".");
  var current = object;
  var result = null;
  for (var i = 0, j = paths.length; i < j; i++) {
    var path = paths[i];
    if (!current) break;
    if (i === j - 1) {
      result = current[path];
      break;
    }
    current = current[path];
  }
  return result;
};
function getPropByPath(obj, path, strict) {
  var tempObj = obj;
  path = path.replace(/\[(\w+)\]/g, ".$1");
  path = path.replace(/^\./, "");
  var keyArr = path.split(".");
  var i = 0;
  for (var len = keyArr.length; i < len - 1; ++i) {
    if (!tempObj && !strict) break;
    var key = keyArr[i];
    if (key in tempObj) {
      tempObj = tempObj[key];
    } else {
      if (strict) {
        throw new Error("please transfer a valid prop path to form item!");
      }
      break;
    }
  }
  return {
    o: tempObj,
    k: keyArr[i],
    v: tempObj ? tempObj[keyArr[i]] : null
  };
}
;
var generateId = exports.generateId = function generateId() {
  return Math.floor(Math.random() * 10000);
};
var valueEquals = exports.valueEquals = function valueEquals(a, b) {
  // see: https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
  if (a === b) return true;
  if (!(a instanceof Array)) return false;
  if (!(b instanceof Array)) return false;
  if (a.length !== b.length) return false;
  for (var i = 0; i !== a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};
var escapeRegexpString = exports.escapeRegexpString = function escapeRegexpString() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return String(value).replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");
};

// TODO: use native Array.find, Array.findIndex when IE support is dropped
var arrayFindIndex = exports.arrayFindIndex = function arrayFindIndex(arr, pred) {
  for (var i = 0; i !== arr.length; ++i) {
    if (pred(arr[i])) {
      return i;
    }
  }
  return -1;
};
var arrayFind = exports.arrayFind = function arrayFind(arr, pred) {
  var idx = arrayFindIndex(arr, pred);
  return idx !== -1 ? arr[idx] : undefined;
};

// coerce truthy value to array
var coerceTruthyValueToArray = exports.coerceTruthyValueToArray = function coerceTruthyValueToArray(val) {
  if (Array.isArray(val)) {
    return val;
  } else if (val) {
    return [val];
  } else {
    return [];
  }
};
var isIE = exports.isIE = function isIE() {
  return !_vue2.default.prototype.$isServer && !isNaN(Number(document.documentMode));
};
var isEdge = exports.isEdge = function isEdge() {
  return !_vue2.default.prototype.$isServer && navigator.userAgent.indexOf("Edge") > -1;
};
var isFirefox = exports.isFirefox = function isFirefox() {
  return !_vue2.default.prototype.$isServer && !!window.navigator.userAgent.match(/firefox/i);
};
var autoprefixer = exports.autoprefixer = function autoprefixer(style) {
  if ((typeof style === "undefined" ? "undefined" : _typeof(style)) !== "object") return style;
  var rules = ["transform", "transition", "animation"];
  var prefixes = ["ms-", "webkit-"];
  rules.forEach(function (rule) {
    var value = style[rule];
    if (rule && value) {
      prefixes.forEach(function (prefix) {
        style[prefix + rule] = value;
      });
    }
  });
  return style;
};
var kebabCase = exports.kebabCase = function kebabCase(str) {
  var hyphenateRE = /([^-])([A-Z])/g;
  return str.replace(hyphenateRE, "$1-$2").replace(hyphenateRE, "$1-$2").toLowerCase();
};
var capitalize = exports.capitalize = function capitalize(str) {
  if (!(0, _types.isString)(str)) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};
var looseEqual = exports.looseEqual = function looseEqual(a, b) {
  var isObjectA = (0, _types.isObject)(a);
  var isObjectB = (0, _types.isObject)(b);
  if (isObjectA && isObjectB) {
    return JSON.stringify(a) === JSON.stringify(b);
  } else if (!isObjectA && !isObjectB) {
    return String(a) === String(b);
  } else {
    return false;
  }
};
var arrayEquals = exports.arrayEquals = function arrayEquals(arrayA, arrayB) {
  arrayA = arrayA || [];
  arrayB = arrayB || [];
  if (arrayA.length !== arrayB.length) {
    return false;
  }
  for (var i = 0; i < arrayA.length; i++) {
    if (!looseEqual(arrayA[i], arrayB[i])) {
      return false;
    }
  }
  return true;
};
var isEqual = exports.isEqual = function isEqual(value1, value2) {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return arrayEquals(value1, value2);
  }
  return looseEqual(value1, value2);
};
var isEmpty = exports.isEmpty = function isEmpty(val) {
  // null or undefined
  if (val == null) return true;
  if (typeof val === "boolean") return false;
  if (typeof val === "number") return !val;
  if (val instanceof Error) return val.message === "";
  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case "[object String]":
    case "[object Array]":
      return !val.length;

    // Map or Set or File
    case "[object File]":
    case "[object Map]":
    case "[object Set]":
      {
        return !val.size;
      }
    // Plain Object
    case "[object Object]":
      {
        return !Object.keys(val).length;
      }
  }
  return false;
};
function rafThrottle(fn) {
  var locked = false;
  return function () {
    var _this = this;
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    if (locked) return;
    locked = true;
    window.requestAnimationFrame(function (_) {
      fn.apply(_this, args);
      locked = false;
    });
  };
}
function objToArray(obj) {
  if (Array.isArray(obj)) {
    return obj;
  }
  return isEmpty(obj) ? [] : [obj];
}
var isMac = exports.isMac = function isMac() {
  return !_vue2.default.prototype.$isServer && /macintosh|mac os x/i.test(navigator.userAgent);
};
export default exports;
export const __esModule = exports.__esModule;
const _isMac = exports.isMac,
  _isEmpty = exports.isEmpty,
  _isEqual = exports.isEqual,
  _arrayEquals = exports.arrayEquals,
  _looseEqual = exports.looseEqual,
  _capitalize = exports.capitalize,
  _kebabCase = exports.kebabCase,
  _autoprefixer = exports.autoprefixer,
  _isFirefox = exports.isFirefox,
  _isEdge = exports.isEdge,
  _isIE = exports.isIE,
  _coerceTruthyValueToArray = exports.coerceTruthyValueToArray,
  _arrayFind = exports.arrayFind,
  _arrayFindIndex = exports.arrayFindIndex,
  _escapeRegexpString = exports.escapeRegexpString,
  _valueEquals = exports.valueEquals,
  _generateId = exports.generateId,
  _getValueByPath = exports.getValueByPath,
  _noop = exports.noop,
  _hasOwn = exports.hasOwn,
  _toObject = exports.toObject,
  _getPropByPath = exports.getPropByPath,
  _rafThrottle = exports.rafThrottle,
  _objToArray = exports.objToArray;
export { _isMac as isMac, _isEmpty as isEmpty, _isEqual as isEqual, _arrayEquals as arrayEquals, _looseEqual as looseEqual, _capitalize as capitalize, _kebabCase as kebabCase, _autoprefixer as autoprefixer, _isFirefox as isFirefox, _isEdge as isEdge, _isIE as isIE, _coerceTruthyValueToArray as coerceTruthyValueToArray, _arrayFind as arrayFind, _arrayFindIndex as arrayFindIndex, _escapeRegexpString as escapeRegexpString, _valueEquals as valueEquals, _generateId as generateId, _getValueByPath as getValueByPath, _noop as noop, _hasOwn as hasOwn, _toObject as toObject, _getPropByPath as getPropByPath, _rafThrottle as rafThrottle, _objToArray as objToArray };